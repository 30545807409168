// Angular import
import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-nav-left',
  templateUrl: './nav-left.component.html',
  styleUrls: ['./nav-left.component.scss']
})
export class NavLeftComponent {
  // public props
  @Output() NavCollapsedMob = new EventEmitter();
  getUserDetails: any;
  greeting: string = "";

  ngOnInit(): void {
    this.getUserDetails = JSON.parse(localStorage.getItem('loggedUserData'));
    this.setGreeting();
  }

  setGreeting(): void {
    const currentTime = new Date().getHours();
    if (currentTime >= 5 && currentTime < 12) {
      this.greeting = 'Good Morning';
    } else if (currentTime >= 12 && currentTime < 15) {
      this.greeting = 'Good Afternoon';
    } else if (currentTime >= 15 && currentTime < 19){
      this.greeting = 'Good Evening';
    } else {
      this.greeting = 'Good Night';
    }
  }

}
