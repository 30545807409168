import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { GuestComponent } from './theme/layout/guest/guest.component';
import { AuthGuard } from './core/guards/auth.guard';
import { LoggedInGuard } from './core/guards/logged-in.guard';
import { PlanGuard } from './core/guards/plan.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing-page',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadComponent: () => import('./demo/default/default.component'),
        canActivate: [AuthGuard],
      },
      {
        path: 'user-list',
        loadComponent: () => import('./theme/layout/admin/pages/users-list/users-list.component').then(c => c.UsersListComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'plan-list',
        loadComponent: () => import('./theme/layout/admin/pages/plan-list/plan-list.component').then(c => c.PlanListComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'product-list',
        loadComponent: () => import('./theme/layout/admin/pages/products/products.component').then(c => c.ProductsComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'tasks',
        loadComponent: () => import('./theme/layout/admin/pages/tasks/tasks.component').then(c => c.TasksComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'user-level',
        loadComponent: () => import('./theme/layout/admin/pages/user-level/user-level.component').then(c => c.UserLevelComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'income-list',
        loadComponent: () => import('./theme/layout/admin/pages/income-list/income-list.component').then(c => c.IncomeListComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'payment-history',
        loadComponent: () => import('./theme/layout/admin/pages/payment-history/payment-history.component').then(c => c.PaymentHistoryComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'withdraw-request',
        loadComponent: () => import('./theme/layout/admin/pages/account-transfer/account-transfer.component').then(c => c.AccountTransferComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'plan-request',
        loadComponent: () => import('./theme/layout/admin/pages/plan-request/plan-request.component').then(c => c.PlanRequestComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'product-levels',
        loadComponent: () => import('./theme/layout/admin/pages/product-level/product-level.component').then(c => c.ProductLevelComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'product-orders',
        loadComponent: () => import('./theme/layout/admin/pages/product-order-requests/product-order-requests.component').then(c => c.ProductOrderRequestsComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'qrcode-settings',
        loadComponent: () => import('./demo/pages/app-settings/app-settings.component').then(c => c.AppSettingsComponent),
        canActivate: [AuthGuard],
      },



      // user menu Routers

      {
        path: 'referral-link',
        loadComponent: () => import('./theme/layout/admin/pages/referral-link/referral-link.component').then(c => c.ReferralLinkComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'my-profile',
        loadComponent: () => import('./theme/layout/admin/pages/my-profile/my-profile.component').then(c => c.MyProfileComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'company-brochures',
        loadComponent: () => import('./theme/layout/admin/pages/company-brochures/company-brochures.component').then(c => c.CompanyBrochuresComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'daily-tasks',
        loadComponent: () => import('./theme/layout/admin/pages/daily-tasks/daily-tasks.component').then(c => c.DailyTasksComponent),
        canActivate: [AuthGuard,PlanGuard],
      },
      {
        path: 'our-channels',
        loadComponent: () => import('./theme/layout/admin/pages/our-channels/our-channels.component').then(c => c.OurChannelsComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'wallets',
        loadComponent: () => import('./theme/layout/admin/pages/wallets/wallets.component').then(c => c.WalletsComponent),
        canActivate: [AuthGuard,PlanGuard],
      },
      {
        path: 'upgrade-package',
        loadComponent: () => import('./theme/layout/admin/pages/upgrade-package/upgrade-package.component').then(c => c.UpgradePackageComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'contact-us',
        loadComponent: () => import('./theme/layout/admin/pages/contact-us/contact-us.component').then(c => c.ContactUsComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'change-password',
        loadComponent: () => import('./theme/layout/admin/pages/change-password/change-password.component').then(c => c.ChangePasswordComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'notification',
        loadComponent: () => import('./theme/layout/admin/pages/notification/notification.component').then(c => c.NotificationComponent),
        canActivate: [AuthGuard,PlanGuard],
      },
      {
        path: 'payment',
        loadComponent: () => import('./theme/layout/admin/pages/payment/payment.component').then(c => c.PaymentComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'team-networks',
        loadComponent: () => import('./theme/layout/admin/pages/my-team/my-team.component').then(c => c.MyTeamComponent),
        canActivate: [AuthGuard,PlanGuard],
      },
      {
        path: 'kyc-verification',
        loadComponent: () => import('./theme/layout/admin/pages/kyc-verification/kyc-verification.component').then(c => c.KycVerificationComponent),
        canActivate: [AuthGuard,PlanGuard],
      },
      {
        path: 'levels-income',
        loadComponent: () => import('./theme/layout/admin/pages/income-list/income-list.component').then(c => c.IncomeListComponent),
        canActivate: [AuthGuard,PlanGuard],
      },
      {
        path: 'activity-list',
        loadComponent: () => import('./theme/layout/admin/pages/recent-activity/recent-activity.component').then(c => c.RecentActivityComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'kyc-list',
        loadComponent: () => import('./theme/layout/admin/pages/kyc-list/kyc-list.component').then(c => c.KycListComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'team-members',
        loadComponent: () => import('./theme/layout/admin/pages/income-list/income-list.component').then(c => c.IncomeListComponent),
        canActivate: [AuthGuard,PlanGuard],
      },
      {
        path: 'repurchase',
        loadComponent: () => import('./theme/layout/admin/pages/product-repurchase/product-repurchase.component').then(c => c.ProductRepurchaseComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'product/:id',
        loadComponent: () => import('./theme/layout/admin/pages/product-details/product-details.component').then(c => c.ProductDetailsComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'my-carts',
        loadComponent: () => import('./theme/layout/admin/pages/my-carts/my-carts.component').then(c => c.MyCartsComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'my-orders',
        loadComponent: () => import('./theme/layout/admin/pages/my-orders/my-orders.component').then(c => c.MyOrdersComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'check-out',
        loadComponent: () => import('./theme/layout/admin/pages/check-out/check-out.component').then(c => c.CheckOutComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'product-payment/:id',
        loadComponent: () => import('./theme/layout/admin/pages/product-payment/product-payment.component').then(c => c.ProductPaymentComponent),
        canActivate: [AuthGuard],
      },
    ]
  },
  {
    path: 'landing-page',
    loadComponent: () => import('./demo/pages/landing-page/landing-page.component').then(c => c.LandingPageComponent),
    canActivate: [LoggedInGuard],
  },
  {
    path: '',
    component: GuestComponent,
    children: [
      {
        path: 'authentication',
        loadChildren: () => import('./demo/pages/authentication/authentication.module').then((m) => m.AuthenticationModule),
        canActivate: [LoggedInGuard],
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
