// Angular import
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Location, LocationStrategy } from '@angular/common';

// project import
import { NavigationItem } from '../navigation';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/core/service/auth.service';

@Component({
  selector: 'app-nav-content',
  templateUrl: './nav-content.component.html',
  styleUrls: ['./nav-content.component.scss']
})
export class NavContentComponent implements OnInit {
  // public props
  @Output() NavCollapsedMob: EventEmitter<any> = new EventEmitter();

  // version
  currentApplicationVersion = environment.appVersion;

  navigation: any;
  windowWidth = window.innerWidth;

  // Constructor
  constructor(
    public nav: NavigationItem,
    private location: Location,
    private locationStrategy: LocationStrategy,
    private _authService : AuthService
  ) {
    
    
  }

  // Life cycle events
  ngOnInit() {
    let getUserDetails = JSON.parse(localStorage.getItem('loggedUserData'));
    const getUserType = Number(getUserDetails.user_type);
    let planDetails = getUserDetails.plan_details.filter(plan=> plan.status === 1)
    if(getUserType === 1){
      this.navigation = this.nav.get().filter(navigation => navigation.id === 'dashboard' || navigation.id === 'admin');
    }else{
      if(planDetails.length > 0){
        this.navigation = this.nav.get().filter(navigation => navigation.id === 'dashboard' || navigation.id === 'user');
      }else{
        if(getUserDetails.email === 'poppysmart2024@gmail.com'){
          this.navigation = this.nav.get().filter(navigation => navigation.id === 'dashboard' || navigation.id === 'user');
        }else{
          this.navigation = this.nav.get().filter(navigation => navigation.id === 'dashboard' || navigation.id === 'inactive-user');
        }
      }
    }
    if (this.windowWidth < 1025) {
      (document.querySelector('.coded-navbar') as HTMLDivElement).classList.add('menupos-static');
    }

    this._authService.userData$.subscribe(value => {
			getUserDetails = value;
      const getUserType = Number(getUserDetails.user_type);
      let planDetails = getUserDetails?.plan_details?.filter(plan=> plan.status === 1)
      if(getUserType === 1){
        this.navigation = this.nav.get().filter(navigation => navigation.id === 'dashboard' || navigation.id === 'admin');
      }else{
        if(planDetails?.length > 0){
          this.navigation = this.nav.get().filter(navigation => navigation.id === 'dashboard' || navigation.id === 'user');
        }else{
          if(getUserDetails.email === 'poppysmart2024@gmail.com'){
            this.navigation = this.nav.get().filter(navigation => navigation.id === 'dashboard' || navigation.id === 'user');
          }else{
            this.navigation = this.nav.get().filter(navigation => navigation.id === 'dashboard' || navigation.id === 'inactive-user');
          }
        }
      }
		});
  }

  fireOutClick() {
    let current_url = this.location.path();
    const baseHref = this.locationStrategy.getBaseHref();
    if (baseHref) {
      current_url = baseHref + this.location.path();
    }
    const link = "a.nav-link[ href='" + current_url + "' ]";
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const up_parent = parent?.parentElement?.parentElement;
      const last_parent = up_parent?.parentElement;
      if (parent?.classList.contains('coded-hasmenu')) {
        parent.classList.add('coded-trigger');
        parent.classList.add('active');
      } else if (up_parent?.classList.contains('coded-hasmenu')) {
        up_parent.classList.add('coded-trigger');
        up_parent.classList.add('active');
      } else if (last_parent?.classList.contains('coded-hasmenu')) {
        last_parent.classList.add('coded-trigger');
        last_parent.classList.add('active');
      }
    }
  }

  navMob() {
    if (this.windowWidth < 1025 && document.querySelector('app-navigation.coded-navbar').classList.contains('mob-open')) {
      this.NavCollapsedMob.emit();
    }
  }
}
