<!-- [ Sidebar Menu ] start -->
<nav>
  <div class="navbar-wrapper">
    <div class="m-header justify-content-between">
      <!-- [ Header Topbar ] start -->
      <a [routerLink]="['/default/']" class="b-brand mt-2">
        <!-- ========   change your logo hear   ============ -->
        <img src="assets/images/logo/logo-1.png" width="100" height="75" class="logo logo-lg" />
      </a>
      <a type="button" class="btn custom-style" (click)="closeMenu()"><i class="fa fa-times" ></i></a>
    </div>
    <app-nav-content (NavCollapsedMob)="navCollapseMob()" class="scroll-div w-100"></app-nav-content>
  </div>
</nav>
<!-- [ Sidebar Menu ] end -->
