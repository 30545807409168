import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserService } from 'src/app/service/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  triggerClose = new Subject<void>();
  private userDataSubject = new BehaviorSubject<string>('default value');
  userData$ = this.userDataSubject.asObservable();
    constructor(private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private _userService : UserService
    ) { }


  register(data: any){
    return this.http.post(`${environment.API_URL}/register`, data);
  }

  sendOtp(data: any){
    return this.http.post(`${environment.API_URL}/send-otp`, data);
  }

  emailVerification(data: any){
    return this.http.post(`${environment.API_URL}/verify-otp`, data);
  }


  // Login request
  login(credentials: any): Observable<any> {
    return this.http.post(`${environment.API_URL}/login`, credentials);
  }

  updateNewPassword(data: any){
    return this.http.post(`${environment.API_URL}/change-passwords`, data);
  }

  /**
   * Logout
   */
  public logout() {
    const data ={
      token : localStorage.getItem('authToken')
    }
    return this.http.post(`${environment.API_URL}/logout`, data)
    .subscribe((res) => {
    this.router.navigate(["/authentication/login"]);
    localStorage.clear();
    localStorage.removeItem('authToken');
    this.toastr.success('Logout successfully', 'Success!');
          // window.location.reload();
    })
  }

  // login status
  get isLoggedIn(): boolean {
    return !!localStorage.getItem('authToken');
  }
  // return true if Token present in local storage [for AuthGuard]
  public loggedIn() {
    return !!localStorage.getItem('authToken');
  }

  getToken() {
    const token = localStorage.getItem('authToken');
    return token ? token : null;
  }

  /**
   * Clear the local storage
  */
  clearLocalStorage() {
    localStorage.clear();
  }




  setData(value: string) {
    this.userDataSubject.next(value);
  }

  getData() {
    return this.userDataSubject.getValue();
  }

  userDetails(){
    const getUserDetails = JSON.parse(localStorage.getItem('loggedUserData'));

    this._userService.getUserDetail(getUserDetails.id)
    .subscribe({
      next: (res) => {
        let userData = res.data;
        this.setData(userData);
        localStorage.setItem('loggedUserData', JSON.stringify(userData));
      },
      error: (err: any) => { 
      this.toastr.error(err.message, 'Failed!');
      },
      complete: () => {
      }
    });
  }

  closeSideMenu() {
    this.triggerClose.next();
  }

}
