import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './core/service/auth.service';
import { UserService } from './service/user.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { interval, map } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Poppys Mart Free Version';
  getUserDetails: any;
  loggedUserDetails: any;
  constructor(private router: Router,private _authService: AuthService,
    private _userService: UserService,
    private toastr: ToastrService
  ) { 
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     // Call your function here
    //     if (!this._authService.loggedIn()) {
    //       if(event.url === '/authentication/login') {
    //         this.router.navigate(['/authentication/login']);
    //       }else{
    //         // this.router.navigate(['/authentication/register']);
    //       }
    //       /**
    //        * if user will login
    //        * redirect to dashboard
    //       */
    //     }
    //   }
    // });
  }

  ngOnInit(): void {
	this.getUserDetails = JSON.parse(localStorage.getItem('loggedUserData'));
    if(this._authService.isLoggedIn){
      this.userDetails();
    }

    this.router.events.subscribe(event=>{
      this.startCheckingTime();
    });
    // if (this._authService.isUserPlanActive()) {
    //   this.router.navigate(['/daily-tasks']); // Navigate to daily tasks if plan is active
    // } else {
    //   this.router.navigate(['/dashboard']); // Navigate to dashboard if plan is not active
    // }
  }

  onRouterChange() {
    // Your function logic here
    if (!this._authService.loggedIn()) {
      /**
       * if user will login
       * redirect to dashboard
      */
      this.router.navigate(['/authentication/login']);
    }
  }

  userDetails(){
    this._userService.getUserDetail(this.getUserDetails.id)
    .subscribe({
      next: (res) => {
        let userData = res.data;
        let plans = userData.plan_details.filter(plan => plan.status !== 2);
        userData.plan_details = plans;
        const type1Plan = userData.plan_details?.filter(plan => plan.type === 1 && plan.status === 1);
        const type2Plan = userData.plan_details?.filter(plan => plan.type === 2 && plan.status === 1);

        if(type1Plan[0]?.remaining_days < 8 && type2Plan[0]?.remaining_days < 8){
          let msg = `Your WhatsApp Status and Ads Watching plan will expire in ${type1Plan[0]?.remaining_days} and ${type2Plan[0]?.remaining_days} days respectively; Please renew immediately to avoid service disruption.`
          this.showWarning(msg)
        }else if(type1Plan[0]?.remaining_days < 8){
          let msg = `Your WhatsApp Status plan will expire in ${type1Plan[0]?.remaining_days} days; please renew immediately to avoid service disruption.`
          this.showWarning(msg)
        }else if(type2Plan[0]?.remaining_days < 8){
          let msg = `Your Ads Watching plan will expire in ${type2Plan[0]?.remaining_days} days; please renew immediately to avoid service disruption.`
          this.showWarning(msg)
        }
        this._authService.setData(userData);
        localStorage.setItem('loggedUserData', JSON.stringify(userData));
      },
      error: (err: any) => { 
      this.toastr.error(err.message, 'Failed!');
      },
      complete: () => {
      }
    });
  }

  showWarning(msg) {
    Swal.fire({
      title: 'Warning!',
      text: msg,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonText: 'Ok!',
      allowOutsideClick : false
      });
    }

    private startCheckingTime() {
      interval(60000) // Check every 60 seconds
        .pipe(
          map(() => {
            const now = new Date();
            return now.getHours() === 0 && now.getMinutes() === 0;
          })
        )
        .subscribe((isMidnight) => {
          if (isMidnight) {
            localStorage.removeItem('vdoEnded');
          }
        });
    }
}
