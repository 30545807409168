import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  icon?: string;
  url?: string;
  classes?: string;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}
const NavigationItems = [
  {
    id: 'dashboard',
    title: '',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        classes: 'nav-item',
        url: '/dashboard',
        icon: 'ti ti-dashboard',
        breadcrumbs: false
      }
    ]
  },
  {
    id: 'admin',
    title: '',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'users',
        title: 'Users List',
        type: 'item',
        classes: 'nav-item',
        url: '/user-list',
        icon: 'ti ti-typography'
      },
      {
        id: 'kyc-list',
        title: 'KYC Verification',
        type: 'item',
        classes: 'nav-item',
        url: '/kyc-list',
        icon: 'ti ti-tools'
      },
      {
        id: 'plan request',
        title: 'Plan Request',
        type: 'item',
        classes: 'nav-item',
        url: '/plan-request',
        icon: 'ti ti-vocabulary'
      },
      {
        id: 'plan',
        title: 'Plan List',
        type: 'item',
        classes: 'nav-item',
        url: '/plan-list',
        icon: 'ti ti-receipt'
      },
      {
        id: 'product',
        title: 'Products',
        type: 'collapse',
        icon: 'ti ti-brand-chrome',
        children: [
          {
            id: 'product-list',
            title: 'Lists',
            type: 'item',
            url: '/product-list',
          },
          {
            id: 'product-levels',
            title: 'Levels',
            type: 'item',
            url: '/product-levels',
          },
          {
            id: 'product-orders',
            title: 'Orders',
            type: 'item',
            url: '/product-orders',
          },
        ]
      },
      
      {
        id: 'tasks',
        title: 'Tasks',
        type: 'item',
        classes: 'nav-item',
        url: '/tasks',
        icon: 'ti ti-plant-2'
      },
      // {
      //   id: 'ads-list',
      //   title: 'Ads Video',
      //   type: 'item',
      //   classes: 'nav-item',
      //   url: '/ads-list',
      //   icon: 'ti ti-brand-chrome'
      // },
      {
        id: 'user-level',
        title: 'User Level',
        type: 'item',
        classes: 'nav-item',
        url: '/user-level',
        icon: 'ti ti-brush'
      },
      // {
      //   id: 'income list',
      //   title: 'Income List',
      //   type: 'item',
      //   classes: 'nav-item',
      //   url: '/income-list',
      //   icon: 'ti ti-vocabulary'
      // },
      {
        id: 'payment-history',
        title: 'Payment History',
        type: 'item',
        classes: 'nav-item',
        url: '/payment-history',
        icon: 'ti ti-crown'
      },
      {
        id: 'transfer',
        title: 'Withdraw Request',
        type: 'item',
        classes: 'nav-item',
        url: '/withdraw-request',
        icon: 'ti ti-key'
      },
      
      {
        id: 'activity-list',
        title: 'Recent Activity',
        type: 'item',
        classes: 'nav-item',
        url: '/activity-list',
        icon: 'ti ti-bell'
      },
      {
        id: 'qr-settings',
        title: 'QR Settings',
        type: 'item',
        classes: 'nav-item',
        url: '/qrcode-settings',
        icon: 'ti ti-plant-2',
      }
      
















      // {
      //   id: 'tabler',
      //   title: 'Tabler',
      //   type: 'item',
      //   classes: 'nav-item',
      //   url: 'https://tabler-icons.io/',
      //   icon: 'ti ti-plant-2',
      //   target: true,
      //   external: true
      // }
    ]
  },
  {
    id: 'user',
    title: '',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'my-profile',
        title: 'My Profile',
        type: 'item',
        classes: 'nav-item',
        url: '/my-profile',
        icon: 'ti ti-typography'
      },
      {
        id: 'kyc',
        title: 'KYC Verification',
        type: 'item',
        classes: 'nav-item',
        url: '/kyc-verification',
        icon: 'ti ti-tools'
      },
      {
        id: 'company-brochures',
        title: 'Company Brochures',
        type: 'item',
        classes: 'nav-item',
        url: '/company-brochures',
        icon: 'ti ti-receipt'
      },
      
      {
        id: 'daily-tasks',
        title: 'Daily Tasks',
        type: 'item',
        classes: 'nav-item',
        url: '/daily-tasks',
        icon: 'ti ti-plant-2'
      },
      {
        id: 'referral-link',
        title: 'Referral Link',
        type: 'item',
        classes: 'nav-item',
        url: '/referral-link',
        icon: 'ti ti-link'
      },
      {
        id: 'our-channels',
        title: 'Our Channels',
        type: 'item',
        classes: 'nav-item',
        url: '/our-channels',
        icon: 'ti ti-brush'
      },
      {
        id: 'wallets',
        title: 'Wallets',
        type: 'item',
        classes: 'nav-item',
        url: '/wallets',
        icon: 'ti ti-wallet'
      },
      {
        id: 'upgrade-package',
        title: 'Upgrade Package',
        type: 'item',
        classes: 'nav-item',
        url: '/upgrade-package',
        icon: 'ti ti-crown'
      },
      {
        id: 'repurchase',
        title: 'Repurchase',
        type: 'item',
        classes: 'nav-item',
        url: '/repurchase',
        icon: 'ti ti-package'
      },
      {
        id: 'team-networks',
        title: 'My Team',
        type: 'collapse',
        icon: 'ti ti-users',
        children: [
          {
            id: 'team-levels',
            title: 'Team Levels',
            type: 'item',
            url: '/team-networks',
          },
          {
            id: 'level-report',
            title: 'Levels Report',
            type: 'item',
            url: '/levels-income',
          },
          {
            id: 'team-members',
            title: 'Team Members',
            type: 'item',
            url: '/team-members',
          }
        ]
      },
      {
        id: 'change-password',
        title: 'Change Password',
        type: 'item',
        classes: 'nav-item',
        url: '/change-password',
        icon: 'ti ti-key'
      },
      {
        id: 'activity-list',
        title: 'Recent Activity',
        type: 'item',
        classes: 'nav-item',
        url: '/activity-list',
        icon: 'ti ti-bell'
      },
      {
        id: 'contact-us',
        title: 'Contact Us',
        type: 'item',
        classes: 'nav-item',
        url: '/contact-us',
        icon: 'ti ti-phone'
      },
    ]
  },

  {
    id: 'inactive-user',
    title: '',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'my-profile',
        title: 'My Profile',
        type: 'item',
        classes: 'nav-item',
        url: '/my-profile',
        icon: 'ti ti-typography'
      },
      {
        id: 'company-brochures',
        title: 'Company Brochures',
        type: 'item',
        classes: 'nav-item',
        url: '/company-brochures',
        icon: 'ti ti-receipt'
      },
      {
        id: 'our-channels',
        title: 'Our Channels',
        type: 'item',
        classes: 'nav-item',
        url: '/our-channels',
        icon: 'ti ti-brush'
      },
      {
        id: 'upgrade-package',
        title: 'Upgrade Package',
        type: 'item',
        classes: 'nav-item',
        url: '/upgrade-package',
        icon: 'ti ti-crown'
      },
      {
        id: 'repurchase',
        title: 'Repurchase',
        type: 'item',
        classes: 'nav-item',
        url: '/repurchase',
        icon: 'ti ti-package'
      },
      {
        id: 'activity-list',
        title: 'Recent Activity',
        type: 'item',
        classes: 'nav-item',
        url: '/activity-list',
        icon: 'ti ti-bell'
      },
      {
        id: 'contact-us',
        title: 'Contact Us',
        type: 'item',
        classes: 'nav-item',
        url: '/contact-us',
        icon: 'ti ti-key'
      },
    ]
  },
];

@Injectable()
export class NavigationItem {
  get() {
    return NavigationItems;
  }
}
