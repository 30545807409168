<router-outlet>
  <!-- Page Loader -->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
  <app-spinner></app-spinner>



  <div id="fixed-social">
    <div>
      <a class="fixed-telegram pulse" href="https://t.me/+RSO_WJKpwPUyZWI1"  target="_blank"><i class="fa fa-telegram"></i> </a>
    </div>
    <!-- <div>
      <a href="#" class="fixed-facebook" target="_blank"><i class="fa fa-facebook"></i> <span>Facebook</span></a>
    </div>
    <div>
      <a href="#" class="fixed-twitter" target="_blank"><i class="fa fa-twitter"></i> <span>Twitter</span></a>
    </div>
    <div>
      <a href="#" class="fixed-gplus" target="_blank"><i class="fa fa-google"></i> <span>Google+</span></a>
    </div>
    <div>
      <a href="#" class="fixed-linkedin" target="_blank"><i class="fa fa-linkedin"></i> <span>LinkedIn</span></a>
    </div>
    <div>
      <a href="#" class="fixed-instagrem" target="_blank"><i class="fa fa-instagram"></i> <span>Instagram</span></a>
    </div>
    <div>
      <a href="#" class="fixed-tumblr" target="_blank"><i class="fa fa-tumblr"></i> <span>Tumblr</span></a>
    </div> -->
  </div>
</router-outlet>


