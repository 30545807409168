<div class="ms-auto">
  <ul class="list-unstyled">
    <li>
      <!-- <button (click)="toggleTheme()">
        Switch to {{ isDarkTheme() ? 'Light' : 'Dark' }} Theme
      </button> -->
      <!-- <div class="dropdown pc-h-item" ngbDropdown>
        <a
          class="pc-head-link head-link-secondary dropdown-toggle arrow-none me-0"
          data-bs-toggle="dropdown"
          href="javascript:"
          ngbDropdownToggle
        >
          <i class="ti ti-bell"></i>
        </a>
        <div class="dropdown-menu dropdown-notification dropdown-menu-end pc-h-dropdown" ngbDropdownMenu>
          <div class="dropdown-header">
            <a href="javascript:!" class="link-primary float-end text-decoration-underline">Mark as all read</a>
            <h5>
              All Notification
              <span class="badge bg-warning rounded-pill ms-1">01</span>
            </h5>
          </div>
          <ng-scrollbar style="height: calc(100vh - 215px)" visibility="hover">
            <div class="dropdown-header px-0 text-wrap">
              <div class="list-group list-group-flush w-100">
                <div class="list-group-item">
                  <select class="form-select">
                    <option value="all">All Notification</option>
                    <option value="new">New</option>
                    <option value="unread">Unread</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <a class="list-group-item list-group-item-action">
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <img src="assets/images/user/avatar-2.jpg" alt="user-image" class="user-avtar" />
                    </div>
                    <div class="flex-grow-1 ms-1">
                      <span class="float-end text-muted">2 min ago</span>
                      <h5>John Doe</h5>
                      <p class="text-body fs-6">It is a long established fact that a reader will be distracted </p>
                      <div class="badge rounded-pill bg-light-danger">Unread</div>
                      <div class="badge rounded-pill bg-light-warning">New</div>
                    </div>
                  </div>
                </a>
                <a class="list-group-item list-group-item-action">
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <div class="user-avtar bg-light-success"><i class="ti ti-building-store"></i></div>
                    </div>
                    <div class="flex-grow-1 ms-1">
                      <span class="float-end text-muted">3 min ago</span>
                      <h5>Store Verification Done</h5>
                      <p class="text-body fs-6">We have successfully received your request.</p>
                      <div class="badge rounded-pill bg-light-danger">Unread</div>
                    </div>
                  </div>
                </a>
                <a class="list-group-item list-group-item-action">
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <div class="user-avtar bg-light-primary"><i class="ti ti-mailbox"></i></div>
                    </div>
                    <div class="flex-grow-1 ms-1">
                      <span class="float-end text-muted">5 min ago</span>
                      <h5>Check Your Mail.</h5>
                      <p class="text-body fs-6">All done! Now check your inbox as you're in for a sweet treat! </p>
                      <button class="btn btn-sm btn-primary">Mail <i class="ti ti-brand-telegram"></i></button>
                    </div>
                  </div>
                </a>
                <a class="list-group-item list-group-item-action">
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <img src="assets/images/user/avatar-1.jpg" alt="user-image" class="user-avtar" />
                    </div>
                    <div class="flex-grow-1 ms-1">
                      <span class="float-end text-muted">8 min ago</span>
                      <h5>John Doe</h5>
                      <p class="text-body fs-6">Uploaded two file on &nbsp;<strong>21 Jan 2020</strong></p>
                      <div class="notification-file d-flex p-3 bg-light-secondary rounded">
                        <i class="ti ti-arrow-bar-to-down"></i>
                        <h5 class="m-0">demo.jpg</h5>
                      </div>
                    </div>
                  </div>
                </a>
                <a class="list-group-item list-group-item-action">
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <img src="assets/images/user/avatar-3.jpg" alt="user-image" class="user-avtar" />
                    </div>
                    <div class="flex-grow-1 ms-1">
                      <span class="float-end text-muted">10 min ago</span>
                      <h5>Joseph William</h5>
                      <p class="text-body fs-6">It is a long established fact that a reader will be distracted </p>
                      <div class="badge rounded-pill bg-light-success">Confirmation of Account</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </ng-scrollbar>
          <div class="dropdown-divider"></div>
          <div class="text-center py-2">
            <a href="javascript:" class="link-primary">Mark as all read</a>
          </div>
        </div>
      </div> -->
    </li>
    <li>
      <div class="dropdown pc-h-item header-user-profile" ngbDropdown>
        <a
          class="pc-head-link head-link-primary dropdown-toggle arrow-none me-0 gap-sm-2"
          data-bs-toggle="dropdown"
          href="javascript:"
          ngbDropdownToggle
        >
          <!-- <img src="assets/images/user/avatar-2.jpg" alt="user-image" class="user-avtar" /> -->
          <div class="avatar-sm d-flex justify-content-center align-items-center">
            <span class="avatar-title rounded-circle">
              {{ getAvatar(getUserDetails.first_name) }}
            </span>
          </div>
          <span>
            <i class="ti ti-settings"></i>
          </span>
        </a>
        <div class="dropdown-menu dropdown-user-profile dropdown-menu-end pc-h-dropdown" ngbDropdownMenu>
          <div class="dropdown-header">
            <h4> <span class="text-muted">{{greeting}}</span>, </h4>
            <h5 class="text-muted"> {{getUserDetails.first_name}} {{getUserDetails.last_name}}</h5>
            <!-- <p class="text-muted">{{getUserDetails.user_type === '1' ? 'Super Admin' : getUserDetails.user_type === '2' ? 'User' : 'Admin'}}</p> -->
            <!-- <form class="header-search">
              <div class="search-btn">
                <i class="ti ti-search"></i>
              </div>
              <input type="search" class="form-control" placeholder="Search profile options" />
            </form> -->
            <hr />
            <ng-scrollbar style="height: 170px" visibility="hover">
              <!-- <div class="upgradeplan-block bg-light-warning rounded">
                <h4>Upgrade your plan</h4>
                <p class="text-muted"> 70% discount for 1 years <br />subscriptions. </p>
                <a href="https://codedthemes.com/item/berry-angular-admin-dashboard-template/" target="_blank" class="btn btn-warning"
                  >Go Premium</a
                >
              </div>
              <hr />
              <div class="settings-block bg-light-primary rounded">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                  <label class="form-check-label" for="flexSwitchCheckDefault">Start DND Mode</label>
                </div>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
                  <label class="form-check-label" for="flexSwitchCheckChecked">Allow Notifications</label>
                </div>
              </div>
              <hr /> -->
              <a [routerLink]="['/my-profile']" class="dropdown-item">
                <i class="ti ti-settings"></i>
                <span>Account Profile</span>
              </a>
              <a [routerLink]="['/my-carts']" class="dropdown-item">
                <i class="ti ti-shopping-cart"></i>
                <span>Cart</span>
              </a>
              <a [routerLink]="['/my-orders']" class="dropdown-item">
                <i class="ti ti-package"></i>
                <span>My Orders</span>
              </a>
              <a [href]="reDirectUrl"  (click)="logOut()" class="dropdown-item">
                <i class="ti ti-logout"></i>
                <span>Logout</span>
              </a>
            </ng-scrollbar>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
