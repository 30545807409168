import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PlanGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
	const getUserDetails = JSON.parse(localStorage.getItem('loggedUserData'));
    const planActivate = getUserDetails.plan_details.length > 0 || getUserDetails.email === 'poppysmart2024@gmail.com' ? true : false;
    if (planActivate) {
      return true; // Allow access to the route.
    } else {
      // Redirect to the login page or another page.
      this.router.navigate(['/dashboard']);
      return false; // Prevent access to the route.
    }
  }
}
