import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(value: number): string {
    // Convert value to string with comma separators
    let formattedValue = value?.toLocaleString('en-IN');
    // Add ₹ symbol
    formattedValue = '₹' + formattedValue;
    // Add decimal places if present
    // if (hasDecimals) {
    //   const decimalPart = (value % 1).toFixed(2);
    //   formattedValue += decimalPart.padEnd(3, '0'); // Ensure 2 decimal places
    // }
    return formattedValue;
  }
}
