import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private _authService: AuthService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401 || err.status === 403) {
          // auto logout if 401 response returned from api
          if(err.error.message !== "Invalid login details"){
            this._authService.clearLocalStorage();
            location.href = '/authentication/login';
          }
        }
        if (err.status === 422) {
          return throwError(() => err.error);
        }
        if (err.status === 421) {
          return throwError(() => err.error);
        }
        if (err.status === 400) {
          return throwError(() => err.error);
        }
        if (err.status === 500) {
          if(err.error.message === 'Unauthenticated.'){
            this._authService.clearLocalStorage();
            location.href = '/authentication/login';
          }
          return throwError(() => err.error);
        }
        if (err.status === 404) {
          // this.router.navigate(['/Page-Not-Found']);
          return throwError(() => err.error);
        }
        if (err.status === 0 && err.error instanceof ProgressEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          // location.href = '/error-connection'
          return throwError(() => err.error);
        }
        const error = err.error || err.statusText;
        return throwError(() => error);
      })
    );

  }
}
