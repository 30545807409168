// Angular import
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from 'src/app/service/theme.service';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss']
})
export class NavRightComponent implements OnInit {
  getUserDetails: any;
  greeting: string = "";
  reDirectUrl: any;
  constructor(private themeService: ThemeService,private router: Router) {
    this.setGreeting();
  }
  ngOnInit(): void {
    this.getUserDetails = JSON.parse(localStorage.getItem('loggedUserData'));
    this.getAvatar(this.getUserDetails.first_name);
    this.reDirectUrl = environment.LANDING_URL ?? '/'
  }
  isDarkTheme(): boolean {
    return this.themeService.isDarkTheme();
  }

  toggleTheme(): void {
    this.themeService.toggleTheme();
  }

  logOut(){
    localStorage.clear();
    // this.router.navigate(['https://poppysmart.in']);
  }

  /**
   * For creating avatar
   * Accepts first and last name and returns capital letters of the
   * first two letters of lender name
   *
   * @param first Name of the lender
   * @returns Avatar text
   */
  getAvatar(first: any): string {
    if (typeof first === 'string') {
      return first.substring(0, 1).toUpperCase();
    }
    return ''; // or some other default value
  }

  setGreeting(): void {
    const currentTime = new Date().getHours();
    if (currentTime >= 5 && currentTime < 12) {
      this.greeting = 'Good Morning';
    } else if (currentTime >= 12 && currentTime < 15) {
      this.greeting = 'Good Afternoon';
    } else if (currentTime >= 15 && currentTime < 19){
      this.greeting = 'Good Evening';
    } else {
      this.greeting = 'Good Night';
    }
  }

}
