import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient,
    private router: Router,
    ) { }


    // Get User request
    getUsers(data: any): Observable<any> {
      return this.http.get(`${environment.API_URL}/v1/admins/users`, {params: data});
    }

    // Get User Details request
    getUserDetail(id: string): Observable<any> {
      return this.http.get(`${environment.API_URL}/v1/admins/users/${id}`);
    }
  
}
