<div class="card">
  <div class="card-header">
    @if (!customHeader) {
    <div class="d-flex justify-content-between">
      <h3>{{ cardTitle }}</h3>
      @if (customButton) {
        <button type="button" class="btn btn-secondary" (click)="buttonClick.emit()">{{customButton}}</button>
      }
    </div>
    }
  </div>
  <div class="card-block">
    <ng-content></ng-content>
  </div>
</div>